<template>
  <div class="ads-item">
    <ins ref="ads" v-bind="ads" />
    <div v-if="showDebug" class="ad-msg">
      {{ ads }}
    </div>
  </div>
</template>

<script>

export default {
  props: {
    ads: {
      type: [String, Object],
      required: true
    },
    showDebug: {
      type: Boolean,
      required: true
    },
  },
}
</script>